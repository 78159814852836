<template>
  <BaseElSelect
    v-model="syncModel"
    v-bind="$attrs"
    :multiple="multiple"
    value-key="id"
    collapse-tags
    :filterable="false"
    :disabled="disabled"
    placeholder="請選擇服務人員"
    no-data-text="暫無數據"
    @change="onChange"
  >
    <BaseElSelectOption
      v-for="item in serviceUnitList"
      :key="item.id"
      :label="item.name"
      :value="item"
    />
  </BaseElSelect>
</template>

<script>
import { GetServiceUnit, GetServiceUnitCount } from '@/api/serviceUnit'
import { mapGetters } from 'vuex'

export default {
  name: 'ServiceUnitSelect',
  props: [
    'model',
    'multiple',
    'disabled',
  ],
  computed: {
    ...mapGetters([
      'shop',
    ]),
    syncModel: {
      get () {
        return this.model
      },
      set (data) {
        this.$emit('update:model', data)
      },
    },
  },

  data: () => ({
    serviceUnitList: [],
  }),

  async mounted () {
    await this.getServiceUnit()
  },

  methods: {
    onChange (unit) {
      this.$emit('change', unit)
    },
    //= > 取得服務人員
    async getServiceUnit () {
      try {
        const count = await this.getServiceUnitCount()
        const config = {
          shopId: this.shop,
          start: 0,
          limit: 100,
        }

        const res = await GetServiceUnit(config)
        this.serviceUnitList = res

        while (this.serviceUnitList.length < count) {
          config.start += config.limit
          const data = await GetServiceUnit(config)
          this.serviceUnitList.push(...data)
          console.log('serviceUnitList', this.serviceUnitList.length)
        }
      } catch (error) {
        console.log(error)
        this.$message.error('取得服務失敗')
      }
    },

    //= > 取得服務人員總數
    async getServiceUnitCount () {
      try {
        return await GetServiceUnitCount({ shopId: this.shop })
      } catch (error) {
        console.log(error)
        this.$message.error('取得服務總數失敗')
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
